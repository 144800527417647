import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";

const PrivateRoute = ({
  Component: ComponentToBeRendered,
  props: componentProps,
  ...rest
}) => {
  const { authState } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={props =>
        authState.isAuthenticated ? (
          <ComponentToBeRendered {...props} {...componentProps} />
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
};

export default PrivateRoute;
