import { Auth } from "aws-amplify";

export const signIn = async (email, password) => {
  try {
    const user = await Auth.signIn(email, password);

    return {
      status: "authenticated",
      user
    };
  } catch (err) {
    return {
      status: "invalidAuth"
    };
  }
};

export const getCurrentAuthenticatedUser = async () => {
  return await Auth.currentSession();
};

export const signOut = async () => await Auth.signOut()