import React, { useState, useEffect, useContext } from "react";
import { signIn, getCurrentAuthenticatedUser } from "../../services/cognito";
import { withRouter } from "react-router";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment
} from "semantic-ui-react";
import { AuthContext } from "../../contexts/AuthContext";

const Login = props => {
  const { authState, authDispatch } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [isLogging, setIsLogging] = useState();

  useEffect(() => {
    console.log(email, password);
  }, [email, password]);

  const login = async () => {
    setError(false);
    setIsLogging(true);

    try {
      const result = await signIn(email, password);
      setIsLogging(false);

      // eslint-disable-next-line default-case
      switch (result.status) {
        case "invalidAuth":
          setError(true);
          break;
        case "authenticated":
          const user = getCurrentAuthenticatedUser();

          authDispatch({
            type: "AUTHENTICATED",
            payload: {
              user
            }
          });
          props.history.push("/");
          break;
      }
    } catch (e) {}
  };

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="left">
          {/* <Image src="/logo.png" /> Log-in to your account */}
          Log-in to your account
        </Header>
        <Form size="large">
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="E-mail address"
              onChange={e => {
                setEmail(e.target.value);
              }}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              onChange={e => {
                setPassword(e.target.value);
              }}
            />
            {error && (
              <Header textAlign="left" as="h5" color="red">
                Email or Password is not correct
              </Header>
            )}
            <Button
              color="teal"
              fluid
              size="large"
              onClick={() => {
                login();
              }}
              disabled={isLogging}
            >
              Login
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default withRouter(Login);
