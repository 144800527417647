import React, { Component, useMemo, useState, useEffect } from "react";
import ReactTable from "../../components/Table";
import { Header } from "semantic-ui-react";
const Home = props => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [sortBy, setSortBy] = useState({
    _id: -1
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "employee_name"
      },
      {
        Header: "Idea Name",
        accessor: "idea_name"
      },
      {
        Header: "Idea Detail",
        accessor: "idea_detail"
      },
      {
        Header: "HMH Entity",
        accessor: "HMH_Entity"
      },
      {
        Header: "Department",
        accessor: "department"
      },
      {
        Header: "Idea Impact",
        accessor: "idea_impact"
      },
      {
        Header: "Idea Category",
        accessor: "idea_category"
      }
    ],
    []
  );

  useEffect(() => {
    const loadData = async () => {
      const response = await fetch(
        `https://9nfq762jbi.execute-api.us-east-1.amazonaws.com/dev/ideas?page=${page}&pageSize=50`
      );
      const ideas = await response.json();
      console.log(ideas);
      setData(ideas.items);
      setPageCount(Math.ceil(ideas.count / 50));
    };
    loadData();
  }, [page]);

  return (
    <div>
      <br></br>

      <Header as="h2" color="teal" textAlign="center">
        Bear's Den Employee Ideas
      </Header>
      <br></br>
      <ReactTable
        columns={columns}
        data={data}
        controlledPageCount={pageCount}
        setSortBy={sort => {}}
        setPageNo={no => {
          setPage(no);
        }}
      />
    </div>
  );
};

export default Home;
