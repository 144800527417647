import React, { useContext, useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import NavBar from "./components/NavBar";
import Routes from "./Routes";
import { getCurrentAuthenticatedUser, signOut } from "./services/cognito";
import { Container, Responsive } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { withRouter } from "react-router";

function App(props) {
  const { authState, authDispatch } = useContext(AuthContext);
  const [isAuthenticating, setIsUserAuthenticating] = useState(true);

  const loadUser = async () => {
    setIsUserAuthenticating(true);
    try {
      const user = await getCurrentAuthenticatedUser();
      authDispatch({
        type: "AUTHENTICATED",
        payload: {
          user
        }
      });
    } catch (e) {
      authDispatch({
        type: "LOGOUT"
      });
    }

    setIsUserAuthenticating(false);
  };

  useEffect(() => {
    loadUser();
  }, []);

  const logout = () => {
    console.log("logogut");
    signOut();
    props.history.push("/login");
    authDispatch({
      type: "LOGOUT"
    });
  };

  return (
    !isAuthenticating && (
      <Responsive>
        {authState.isAuthenticated && (
          <NavBar
            rightItems={[
              {
                as: "a",
                content: "Logout",
                key: "logout",
                onClick: () => {
                  logout();
                }
              }
            ]}
          />
        )}
        <Container style={{ marginTop: "3em" }}>
          <Routes />
        </Container>
      </Responsive>
    )
  );
}

export default withRouter(App);
