import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/Home";
import Login from "./pages/Login";

export default () => (
  <Switch>
    <PrivateRoute path="/" exact Component={Home} />
    <Route path="/login" exact component={Login} />
  </Switch>
);
