import React, { Component } from "react";
import { Menu } from "semantic-ui-react";

const NavBar = ({ leftItems=[], rightItems=[] }) => {
  return (
    <Menu fixed="top" inverted>
      {leftItems.map(item => (
        <Menu.Item {...item} />
      ))}
      <Menu.Menu position="right">
        {rightItems.map(item => (
          <Menu.Item {...item} />
        ))}
      </Menu.Menu>
    </Menu>
  );
};

export default NavBar;
