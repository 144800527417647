import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AuthContextProvider } from "./contexts/AuthContext";
import Amplify from 'aws-amplify'
import AmplifyConfig from './amplify.config.json'
import {BrowserRouter as Router} from 'react-router-dom'
Amplify.configure({
  Auth: {
    region: AmplifyConfig.region, 
    userPoolId: AmplifyConfig.userPoolId, 
    userPoolWebClientId: AmplifyConfig.appClientId, 
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  }, 
})



ReactDOM.render(
  <AuthContextProvider>
    <Router>
    <App />
    </Router>
  </AuthContextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
