import React, { useReducer, createContext } from "react";
const AuthContext = createContext();

const initialState = {
  isAuthenticated: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGOUT":
      return { ...state, isAuthenticated: false, user: null };
    case "AUTHENTICATED":
      return { ...state, isAuthenticated: true, user: action.payload.user };

    default:
      return initialState;
  }
};

function AuthContextProvider(props) {
  const [authState, authDispatch] = useReducer(reducer, initialState);
  const value = { authState, authDispatch };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

export { AuthContext, AuthContextProvider };
